module.exports = function(n, rad = 1, x0 = 0, y0 = 0) {
  const points = Array(n)
    .fill()
    .map((v, i) => {
      let phi = (i * Math.PI * 2) / n;
      let x = x0 + rad * Math.cos(phi);
      let y = y0 + rad * Math.sin(phi);
      return { x: x, y: y };
    });

  return points;
};
